@mixin font($weight, $size: 16px, $family: "Manrope") {
  font:
    $weight normal $size $family,
    sans-serif;
  line-height: 1.5;
}

@mixin people-list($icon-size: 20px, $font-size: 14px) {
  img {
    object-fit: cover;
    border-radius: 50%;
    width: $icon-size;
    height: $icon-size;
    padding-right: 10px;
  }

  span.name {
    @include font(400, $font-size);
  }

  span.role {
    @include font(400, $font-size);
  }
}

@mixin shadow-icon($position: "bottom", $diameter: 24px) {
  position: absolute;
  display: inline-block;
  width: $diameter;
  height: $diameter;
  font-size: 20px;
  text-align: center;
  line-height: $diameter;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;

  @if $position == "bottom" {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    box-shadow: inset 0 4px 6px -4px #ccc;
  } @else if $position == "left" {
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    box-shadow: inset -4px 0 6px -4px #ccc;
  } @else {
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    box-shadow: inset -4px 0 6px -4px #ccc;
  }

  * {
    font-size: 20px;
    line-height: $diameter;
    text-align: center;
  }
}

@mixin absoluteCenter() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin absoluteWhole() {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
