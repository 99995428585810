$accent: #ffab41;
$primary: #054c61;
$secondary: #2a9d8f;

$danger: #fd2841;
$warning: #ffab41;

$text-color-lighter: #707579;
$text-color: #3c4043;
$disabled-text-color: #9b9b9b;
$border-color: #79747e;
$border-color-lighter: #b0b0b0;

$gray-background: #efefef;
$beige-background: #f6f2ea;
$blueish-background: #ecf0f1;

$link-color: #87ceeb;

$initials-avatar: #{$secondary};

:root {
  --sk_highlight_accent: 29, 155, 209;
  --sk_secondary_highlight: 242, 199, 68;
  --mat-form-field-container-height: 48px;
}
