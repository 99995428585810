.mdc-linear-progress__bar-inner {
  border-radius: 0;
  animation-name: cometTail !important;
  animation-duration: 1.7s !important;
  animation-iteration-count: infinite !important;
  animation-delay: 1s !important;
}

.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  border-radius: 8px;
  background: var(--Jungle-green-Jungle-green-10, #eaf5f4);
}

.completed {
  .mdc-linear-progress__bar-inner {
    animation: none !important;
  }
}

@keyframes cometTail {
  0% {
    border-radius: 0;
  }

  50% {
    border-radius: 0 50% 30% 0;
  }

  75% {
    border-radius: 0;
  }

  100% {
    border-radius: 0;
  }
}
