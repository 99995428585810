.d-flex {
  display: flex;
}

.d-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.d-inline-flex {
  display: inline-flex;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.d-flex-center {
  display: flex;
  align-items: center;
}

.d-flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.d-flex-center2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.grow-1 {
  flex-grow: 1;
  align-items: flex-end;
}
