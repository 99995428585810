.dialog-with-padding {
  .mat-mdc-dialog-surface {
    padding: 10px;
  }
}

.dialog-with-extra-padding {
  .mat-mdc-dialog-surface {
    width: 600px;
    max-width: 95vw;
    padding: 30px;
    border-radius: 20px;
  }

  &.pr-cycle-step-action {
    .mat-mdc-dialog-surface {
      width: 710px;
    }
  }
}

.transparent-dialog {
  mat-dialog-container#mat-mdc-dialog-0 {
    --mdc-dialog-container-elevation-shadow: none;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: none;
    box-shadow: none;
  }
}

@media (max-width: 650px) {
  .dialog-with-padding,
  .cdk-overlay-pane {
    width: 95vw !important;
    max-width: 95vw !important;
  }
}

@media (max-width: 500px) {
  .dialog-with-extra-padding {
    .mat-mdc-dialog-surface {
      padding: 15px;
    }
  }
}
