@import "./theme";

[class^="icon-"],
[class*=" icon-"] {
  content: "";
  background-color: black;
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;

  &[color="primary"] {
    background-color: $primary;
  }
}

.icon-add-circle {
  mask: no-repeat 50% 50% url("/assets/icons/add-circle.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/add-circle.svg");
}
.icon-close-square {
  mask: no-repeat 50% 50% url("/assets/icons/close-square.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/close-square.svg");
}
.icon-eye-closed {
  mask: no-repeat 50% 50% url("/assets/icons/eye-closed.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/eye-closed.svg");
}
.icon-notes {
  mask: no-repeat 50% 50% url("/assets/icons/notes.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/notes.svg");
}
.icon-trash-bin-outlined {
  mask: no-repeat 50% 50% url("/assets/icons/trash-bin-outlined.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/trash-bin-outlined.svg");
}
.icon-archive {
  mask: no-repeat 50% 50% url("/assets/icons/archive.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/archive.svg");
}
.icon-copy {
  mask: no-repeat 50% 50% url("/assets/icons/copy.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/copy.svg");
}
.icon-feed {
  mask: no-repeat 50% 50% url("/assets/icons/feed.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/feed.svg");
}
.icon-feed-filled {
  mask: no-repeat 50% 50% url("/assets/icons/feed-filled.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/feed-filled.svg");
}
.icon-phone-calling {
  mask: no-repeat 50% 50% url("/assets/icons/phone-calling.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/phone-calling.svg");
}
.icon-user-circle {
  mask: no-repeat 50% 50% url("/assets/icons/user-circle.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/user-circle.svg");
}
.icon-user-circle-filled {
  mask: no-repeat 50% 50% url("/assets/icons/user-circle-filled.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/user-circle-filled.svg");
}
.icon-bell {
  mask: no-repeat 50% 50% url("/assets/icons/bell.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/bell.svg");
}
.icon-cup-star {
  mask: no-repeat 50% 50% url("/assets/icons/cup-star.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/cup-star.svg");
}
.icon-filter {
  mask: no-repeat 50% 50% url("/assets/icons/filter.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/filter.svg");
}
.icon-slack-mark-black {
  mask: no-repeat 50% 50% url("/assets/icons/slack-mark-black.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/slack-mark-black.svg");
}
.icon-user-id {
  mask: no-repeat 50% 50% url("/assets/icons/user-id.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/user-id.svg");
}
.icon-branching-paths-up {
  mask: no-repeat 50% 50% url("/assets/icons/branching-paths-up.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/branching-paths-up.svg");
}
.icon-dialog {
  mask: no-repeat 50% 50% url("/assets/icons/dialog.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/dialog.svg");
}
.icon-flag-plus {
  mask: no-repeat 50% 50% url("/assets/icons/flag-plus.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/flag-plus.svg");
}
.icon-square-forward {
  mask: no-repeat 50% 50% url("/assets/icons/square-forward.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/square-forward.svg");
}
.icon-square-forward-filled {
  mask: no-repeat 50% 50% url("/assets/icons/square-forward-filled.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/square-forward-filled.svg");
}
.icon-user-rounded {
  mask: no-repeat 50% 50% url("/assets/icons/user-rounded.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/user-rounded.svg");
}
.icon-call-chat {
  mask: no-repeat 50% 50% url("/assets/icons/call-chat.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/call-chat.svg");
}
.icon-call-chat-filled {
  mask: no-repeat 50% 50% url("/assets/icons/call-chat-filled.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/call-chat-filled.svg");
}
.icon-diskette {
  mask: no-repeat 50% 50% url("/assets/icons/diskette.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/diskette.svg");
}
.icon-flip-vertical {
  mask: no-repeat 50% 50% url("/assets/icons/flip-vertical.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/flip-vertical.svg");
}
.icon-square-top-down {
  mask: no-repeat 50% 50% url("/assets/icons/square-top-down.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/square-top-down.svg");
}
.icon-users-group-rounded {
  mask: no-repeat 50% 50% url("/assets/icons/users-group-rounded.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/users-group-rounded.svg");
}
.icon-users-group-rounded-filled {
  mask: no-repeat 50% 50% url("/assets/icons/users-group-rounded-filled.svg");
  -webkit-mask: no-repeat 50% 50%
    url("/assets/icons/users-group-rounded-filled.svg");
}
.icon-chat-square-arrow {
  mask: no-repeat 50% 50% url("/assets/icons/chat-square-arrow.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/chat-square-arrow.svg");
}
.icon-document-add {
  mask: no-repeat 50% 50% url("/assets/icons/document-add.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/document-add.svg");
}
.icon-graph-up {
  mask: no-repeat 50% 50% url("/assets/icons/graph-up.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/graph-up.svg");
}
.icon-graph-up-filled {
  mask: no-repeat 50% 50% url("/assets/icons/graph-up-filled.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/graph-up-filled.svg");
}
.icon-star {
  mask: no-repeat 50% 50% url("/assets/icons/star.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/star.svg");
}
.icon-star-outlined {
  mask: no-repeat 50% 50% url("/assets/icons/star-outlined.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/star-outlined.svg");
}
.icon-close-alt {
  mask: no-repeat 50% 50% url("/assets/icons/close-alt.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/close-alt.svg");
}
.icon-document-medicine {
  mask: no-repeat 50% 50% url("/assets/icons/document-medicine.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/document-medicine.svg");
}
.icon-map {
  mask: no-repeat 50% 50% url("/assets/icons/map.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/map.svg");
}
.icon-text-field {
  mask: no-repeat 50% 50% url("/assets/icons/text-field.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/text-field.svg");
}
.icon-close-circle {
  mask: no-repeat 50% 50% url("/assets/icons/close-circle.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/close-circle.svg");
}
.icon-dropdown {
  mask: no-repeat 50% 50% url("/assets/icons/dropdown.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/dropdown.svg");
}
.icon-microsoft-teams {
  mask: no-repeat 50% 50% url("/assets/icons/microsoft-teams.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/microsoft-teams.svg");
}
.icon-transmission {
  mask: no-repeat 50% 50% url("/assets/icons/transmission.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/transmission.svg");
}
.icon-eye {
  mask: no-repeat 50% 50% url("/assets/icons/eye.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/eye.svg");
}
.icon-chat-round-dots {
  mask: no-repeat 50% 50% url("/assets/icons/chat-round-dots.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/chat-round-dots.svg");
}
.icon-incognito {
  mask: no-repeat 50% 50% url("/assets/icons/incognito.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/incognito.svg");
}
.icon-pie-chart {
  mask: no-repeat 50% 50% url("/assets/icons/pie-chart.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/pie-chart.svg");
}
.icon-pie-chart-filled {
  mask: no-repeat 50% 50% url("/assets/icons/pie-chart-filled.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/pie-chart-filled.svg");
}
.icon-clipboard-check {
  mask: no-repeat 50% 50% url("/assets/icons/clipboard-check.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/clipboard-check.svg");
}
.icon-check-circle {
  mask: no-repeat 50% 50% url("/assets/icons/check-circle.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/check-circle.svg");
}
.icon-record-circle {
  mask: no-repeat 50% 50% url("/assets/icons/record-circle.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/record-circle.svg");
}
.icon-traffic {
  mask: no-repeat 50% 50% url("/assets/icons/traffic.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/traffic.svg");
}
.icon-pen {
  mask: no-repeat 50% 50% url("/assets/icons/pen.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/pen.svg");
}
.icon-pen-underlined {
  mask: no-repeat 50% 50% url("/assets/icons/pen-underlined.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/pen-underlined.svg");
}
.icon-link {
  mask: no-repeat 50% 50% url("/assets/icons/link.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/link.svg");
}
.icon-maximize-square {
  mask: no-repeat 50% 50% url("/assets/icons/maximize-square.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/maximize-square.svg");
}
.icon-dropdown-bold {
  mask: no-repeat 50% 50% url("/assets/icons/dropdown-bold.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/dropdown-bold.svg");
}
.icon-logs {
  mask: no-repeat 50% 50% url("/assets/icons/logs.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/logs.svg");
}
.icon-stars {
  mask: no-repeat 50% 50% url("/assets/icons/stars.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/stars.svg");
}
.icon-puzzle {
  mask: no-repeat 50% 50% url("/assets/icons/puzzle.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/puzzle.svg");
}
.icon-settings {
  mask: no-repeat 50% 50% url("/assets/icons/settings.svg");
  -webkit-mask: no-repeat 50% 50% url("/assets/icons/settings.svg");
}
