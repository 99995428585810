$margins: 10px, 15px, 20px, 30px, 40px, 50px;

$sizePercentageMap: (
  "1": 8.333%,
  "2": 16.667%,
  "3": 25%,
  "4": 33.333%,
  "5": 41.667%,
  "6": 50%,
  "7": 58.333%,
  "8": 66.667%,
  "9": 75%,
  "10": 83.333%,
  "11": 91.667%,
);

.row {
  width: 100%;

  @each $size, $percentage in $sizePercentageMap {
    .col-#{$size} {
      width: $percentage;

      @each $margin in $margins {
        &.m-right-#{$margin},
        &.m-left-#{$margin} {
          width: calc(#{$percentage} - #{$margin});
        }
      }
    }
  }
}

.col-12 {
  width: 100%;
}
