.mdc-tab {
  min-width: 80px !important;
  padding: 0 !important;
  flex-grow: 0 !important;
  margin-right: 40px !important;

  &:last-of-type {
    margin-right: 0;
    &:hover {
      .mdc-tab__text-label {
        letter-spacing: 0.906px !important;
      }
    }
  }
}

.mat-mdc-tab {
  &.mdc-tab--active,
  &:hover {
    .mdc-tab__text-label {
      font-weight: 700;
      letter-spacing: 0.955px !important;
      color: var(--Rich-black-Rich-black, #021922) !important;
    }
  }

  .mdc-tab__ripple {
    display: none;
  }

  .mdc-tab__text-label {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1.2px;
  }

  .mdc-tab-indicator .mdc-tab-indicator__content {
    border: solid 1.5px var(--japanese-indigo-japanese-indigo, #264653);
    border-radius: 3px;
    width: 30px;
  }
}

.mat-mdc-optgroup-label {
  font-weight: 600;
  color: $text-color;
}

// .mat-mdc-tab-body-wrapper,
// .mat-mdc-tab-body-content,
// .mat-mdc-tab-body.mat-mdc-tab-body-active {
//   overflow-y: visible!important;
// } 