mat-form-field .mdc-text-field--outlined .mdc-notched-outline {
  .mdc-notched-outline__trailing,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__leading {
    border-width: 2px !important;
    border-color: $border-color;
  }

  .mdc-notched-outline__leading {
    border-radius: 10px 0 0 10px;
  }

  .mdc-notched-outline__trailing {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

mat-form-field.right-notch-flat {
  .mdc-text-field--outlined .mdc-notched-outline {
    .mdc-notched-outline__trailing {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.mat-mdc-text-field-wrapper {
  &.mdc-text-field--outlined {
    .mat-mdc-form-field-infix {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 48px;
    }

    .mdc-text-field__input {
      &:not(textarea) {
        height: 48px;
      }
    }

    textarea.mdc-text-field__input {
      padding-top: 6px;
      padding-bottom: 6px;
    }

    .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
      --mat-mdc-form-field-label-transform: translateY(-30px)
        scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
    }
  }
}

.mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
  height: 25px;
}

.white-fields {
  .mat-mdc-text-field-wrapper.mdc-text-field {
    background-color: white;
    border-radius: 10px;
  }
}

mat-error {
  font-size: 12px;
}

.mat-mdc-select {
  padding-top: 12px;
}

.no-hint {
  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    display: none;
  }
}
