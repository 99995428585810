.mat-mdc-raised-button:not(:disabled).mat-secondary {
  background-color: $secondary;
  color: #fff;

  i {
    background-color: #fff;
  }
}

button.mdc-button {
  border-radius: 8px;
}

.mdc-button.mat-mdc-raised-button.mat-accent:not(:disabled) {
  .mdc-button__label {
    color: white !important;
  }
  .mat-icon {
    color: white !important;
  }
}

.snackbar-button {
  button {
    --mat-mdc-button-persistent-ripple-color: $background-color !important;
  }

  .mdc-button__label {
    color: white !important;
  }
}

button.mdc-button {
  box-shadow: none!important;
}

button[color="accent"] {
  i {
    background-color: white;
  }
}

.ai-assistant-btn {
  border-radius: 4px;
  overflow: hidden;

  button.mdc-button {
    color: white;
    white-space: nowrap;
    border: 3px solid transparent;
    border-image: conic-gradient(
        from var(--angle),
        red,
        yellow,
        lime,
        aqua,
        blue,
        magenta,
        red
      )
      1;

    &[disabled] {
      animation: 5s rotate linear infinite;
    }

    i {
      background-color: white;
    }
  }
}

button:disabled {
  app-icon {
    opacity: 0.3;
  }
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

button.unfold {
  border-radius: 10px;
  width: 80px;
  min-width: 80px;
  height: 36px;
  border: none;
  background-color: var(--Rich-black-Rich-black-20, #ccd1d3);
  box-shadow: none;
  cursor: pointer;

  i {
    transform: rotateX(180deg);
    transition: transform ease 0.5s;
  }

  app-icon {
    transform: rotateX(0);
    transition: transform ease 0.5s;
  }
}
