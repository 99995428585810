@import "/src/core/styles/theme";

mat-checkbox.mat-secondary {
  .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mdc-checkbox
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mdc-checkbox
    .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    background-color: $secondary;
    border-color: $secondary;
  }

  .mdc-checkbox
    .mdc-checkbox__native-control:focus:checked
    ~ .mdc-checkbox__ripple {
    background-color: $secondary;
  }
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate="true"]
  )
  ~ .mdc-checkbox__background {
  border-radius: 6px;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  background-color: transparent !important;
  border-radius: 6px;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: #084058 !important;
  transform: scale(0.8);
}

.bold {
  .mat-mdc-checkbox label {
    font-weight: 600;
  }
}

.mat-mdc-checkbox label {
  width: 100%;
}

.align-start {
  &.mat-mdc-checkbox {
    .mdc-form-field {
      align-items: flex-start;
    }

    label {
      padding-top: 8px;
    }
  }
}
