.rating-question {
  .mdc-radio {
    display: none;
  }

  .mdc-label {
    padding-left: 0;
    padding-right: 5px;
  }
}
