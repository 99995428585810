@import "../theme";

$thicknesses: 1px, 2px, 3px, 4px;

.separator {
  display: block;
  width: 100%;
  height: 1px;
  background-color: $border-color;
  border: none;

  @each $thickness in $thicknesses {
    .thickness-#{$thickness} {
      height: $thickness;
    }
  }
}

.v-separator {
  display: block;
  height: 100%;
  min-height: 10px;
  width: 1px;
  background-color: $border-color;

  @each $thickness in $thicknesses {
    .thickness-#{$thickness} {
      width: $thickness;
    }
  }
}
