:root {
  --Rich-black-Rich-black: #021922;
  --Rich-black-Rich-black-80: #35474e;
  --Rich-black-Rich-black-60: #67757a;
  --Rich-black-Rich-black-40: #9aa3a7;
  --Rich-black-Rich-black-20: #ccd1d3;
  --Rich-black-Rich-black-10: #e6e8e9;
  --Rich-black-Rich-black-5: #f2f3f4;

  --Japanese-indigo-Japanese-indigo: #264653;
  --Japanese-indigo-Japanese-indigo-80: #516b75;
  --Japanese-indigo-Japanese-indigo-60: #7d9098;
  --Japanese-indigo-Japanese-indigo-40: #a8b5ba;
  --Japanese-indigo-Japanese-indigo-20: #d4dadd;
  --Japanese-indigo-Japanese-indigo-10: #e9ecee;
  --Japanese-indigo-Japanese-indigo-5: #f4f6f6;

  --Myrtle-green-Myrtle-green: #287271;
  --Myrtle-green-Myrtle-green-80: #538e8d;
  --Myrtle-green-Myrtle-green-60: #7eaaaa;
  --Myrtle-green-Myrtle-green-40: #a9c7c6;
  --Myrtle-green-Myrtle-green-20: #d4e3e3;
  --Myrtle-green-Myrtle-green-10: #e9f1f1;
  --Myrtle-green-Myrtle-green-5: #f4f8f8;

  --Jungle-green-Jungle-green: #2a9d8f;
  --Jungle-green-Jungle-green-80: #55b1a5;
  --Jungle-green-Jungle-green-60: #7fc4bc;
  --Jungle-green-Jungle-green-40: #aad8d2;
  --Jungle-green-Jungle-green-20: #d4ebe9;
  --Jungle-green-Jungle-green-10: #eaf5f4;
  --Jungle-green-Jungle-green-5: #f4f8f8;

  --Sunglow-Sunglow: #fccb40;
  --Sunglow-Sunglow-80: #fcd566;
  --Sunglow-Sunglow-60: #fde08d;
  --Sunglow-Sunglow-40: #feeab3;
  --Sunglow-Sunglow-20: #fef5d9;
  --Sunglow-Sunglow-10: #fffaec;
  --Sunglow-Sunglow-5: #fffcf5;

  --Sandy-brown-Sandy-brown: #f4a261;
  --Sandy-brown-Sandy-brown-80: #f6b581;
  --Sandy-brown-Sandy-brown-60: #f8c7a0;
  --Sandy-brown-Sandy-brown-40: #fbdac0;
  --Sandy-brown-Sandy-brown-20: #fdecdf;
  --Sandy-brown-Sandy-brown-10: #fef6ef;
  --Sandy-brown-Sandy-brown-5: #fefaf7;

  --Burnt-sienna-Burnt-sienna: #e76f51;
  --Burnt-sienna-Burnt-sienna-80: #ec8c74;
  --Burnt-sienna-Burnt-sienna-60: #f1a997;
  --Burnt-sienna-Burnt-sienna-40: #f5c5b9;
  --Burnt-sienna-Burnt-sienna-20: #fae2dc;
  --Burnt-sienna-Burnt-sienna-10: #fdf1ee;
  --Burnt-sienna-Burnt-sienna-5: #fef8f6;
}
