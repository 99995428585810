.spinner-container {
  position: relative;
  z-index: 1;
  padding: 30px 0;
  box-sizing: border-box;
  flex-wrap: wrap;

  &:not(.auto-height) {
    height: 100%;
  }

  p {
    text-align: center;
    width: 100%;
  }
}
