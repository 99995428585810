mat-chip-grid {
  & + input {
    width: auto;
  }
}

.chips .mdc-evolution-chip-set {
  position: relative;

  .mdc-evolution-chip-set__chips {
    justify-content: flex-start;
    margin-left: 0;
    box-sizing: border-box;
    padding: 0 6px;
  }

  .mdc-evolution-chip {
    border-radius: 8px;
    border: 1px solid $border-color;
    background: white;
    margin: -2px 0 6px 4px;

    &:first-of-type {
      position: initial;
      margin-left: 0;
    
      &::before {
        position: absolute;
        top: -10px;
        left: 6px;
        width: calc(100% - 12px);
        content: "";
        display: block;
        border-bottom: solid 1px var(--Rich-black-Rich-black-20, #ccd1d3);
      }
    }

    span {
      max-width: 100%;
      overflow: hidden;
    }

    &:not(.mdc-evolution-chip--disabled) {
      background: white;
    }

    .mat-mdc-chip-action-label {
      font-weight: 700;
      color: var(--Rich-black-Rich-black, #021922);
    }
  }
}

.mat-mdc-chip-remove {
  opacity: 1;

  mat-icon {
    color: var(--Rich-black-Rich-black-60, #67757a);
  }
}

.chips .mat-mdc-text-field-wrapper {
  padding: 0;
}

