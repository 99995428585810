$sizes: 0, 5px, 10px, 15px, 20px, 25px, 30px;

@each $size in $sizes {
  .p-#{$size} {
    padding: $size;
  }

  .p-right-#{$size} {
    padding-right: $size;
  }

  .p-left-#{$size} {
    padding-left: $size;
  }

  .p-top-#{$size} {
    padding-top: $size;
  }

  .p-bottom-#{$size} {
    padding-bottom: $size;
  }
}
