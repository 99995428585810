/* You can add global styles to this file, and also import other style files */
@import "icons";
@import "variables";
@import "globals";
@import "theme";
@import "mixins";
@import "mixins/no-scrollbar";

@import "./components/separator";
@import "./components/spinner";
@import "./components/skeleton";

@import "margins";
@import "paddings";
@import "text-colors";

@import "grid";
@import "flexbox";

@import "material-angular-overrides";
@import "../../../node_modules/quill/dist/quill.snow.css";
@import "./components/quill-editor";

* {
  font-family: "Manrope", sans-serif;
}

html,
body {
  height: 100%;
  background-color: white;
  color: var(--Rich-black-Rich-black);
}

body {
  margin: 0;
}

p {
  font-family: "Manrope", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.clickable {
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.no-pointer-events {
  pointer-events: none;
}

.d-block {
  display: block;
}

.hidden {
  display: none !important;
}

.no-wrap {
  white-space: nowrap;
}

.priority {
  &.low {
    color: #fd2841;
  }

  &.medium {
    color: #ff601a;
  }

  &.high {
    color: var(--Jungle-green-Robin-Egg-Blue, #00cfb6);
  }
}

.material-symbols-outlined {
  font-variation-settings:
    "FILL" 0,
    "wght" 400,
    "GRAD" -25,
    "opsz" 48;
}

.add-to-slack {
  height: 36px;

  img {
    height: 100%;
  }
}

.logo-btn {
  height: 36px;

  img {
    height: 18px;
  }
}

.backgrounded-text {
  background: #f9f9f9;
  padding: 10px;
  margin: 10px 0 0;
  border-radius: 10px;
  font-size: 14px;
  color: var(--Rich-black-Rich-black-80, #35474e);

  &.bolder {
    font-size: 16px;
    font-weight: bold;
  }
}

.d-inline-block {
  display: inline-block;
}

textarea {
  @include noScrollBar();
}
