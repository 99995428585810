$sizes: 0px, 5px, 10px, 12px, 15px, 20px, 25px, 30px, 40px, 50px, 60px, 100px;

@each $size in $sizes {
  .m-top-#{$size} {
    margin-top: $size;
  }

  .m-bottom-#{$size} {
    margin-bottom: $size;
  }

  .m-right-#{$size} {
    margin-right: $size;
  }

  .m-left-#{$size} {
    margin-left: $size;
  }
}

.m-left-auto {
  margin-left: auto;
}

.m-right-auto {
  margin-right: auto;
}
