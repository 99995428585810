.date-dropdown.select {
  padding-left: 10px !important;
  padding-right: 0 !important;

  mat-select {
    padding: 0;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-notch-piece {
    &.mdc-notched-outline__leading,
    &.mdc-notched-outline__trailing {
      display: none;
    }

    &.mdc-notched-outline__notch {
      border: none;
    }
  }

  .mat-mdc-text-field-wrapper {
    &.mdc-text-field--outlined {
      padding: 0 10px 0 5px;

      .mat-mdc-form-field-infix {
        min-height: 0;
        width: 95px;
      }
    }

    .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 50%;
    }

    &.mdc-text-field--outlined
      .mdc-notched-outline--upgraded
      .mdc-floating-label--float-above {
      transform: translateY(-27px) scale(0.7);
    }
  }

  .mat-mdc-select-min-line {
    font-size: 12px;
    font-weight: 700;
    color: var(--Rich-black-Rich-black-80, #35474e);
  }

  &:hover .mat-mdc-select-min-line {
    color: white;
  }
}
