@use "sass:map";

$colors: (
  "yellow": #f4a100,
  "red": #e74c3c,
  "blue": #3498db,
  "green": #2ecc71,
  "white": #ecf0f1,
  "black": #2c3e50,
  "gray": #95a5a6,
);

@each $colorName, $colorCode in $colors {
  .color-#{$colorName} {
    color: $colorCode;
  }
}

.red-text {
  color: map.get($colors, "red");
}
