@import "./theme";
@import "./mixins/no-scrollbar";

@import "./material-angular-overrides/button";
@import "./material-angular-overrides/checkbox";
@import "./material-angular-overrides/chips";
@import "./material-angular-overrides/datepicker";
@import "./material-angular-overrides/dialog";
@import "./material-angular-overrides/expansion-panel";
@import "./material-angular-overrides/form-field";
@import "./material-angular-overrides/list";
@import "./material-angular-overrides/progress";
@import "./material-angular-overrides/radio";
@import "./material-angular-overrides/select";
@import "./material-angular-overrides/slide-toggle";
@import "./material-angular-overrides/spinner";
@import "./material-angular-overrides/tabs";
@import "./material-angular-overrides/timepicker";

div.mat-mdc-autocomplete-panel {
  @include noScrollBar();
}
